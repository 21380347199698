.app--check {
	margin: 12px 0 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	&:not(.checked):not(:disabled):hover {
		.app--check-trigger {
			background: lighten($primaryBg, 14);
		}
		.app--check-label {
			color: darken($textWhite, 6);
		}
	}
	&.checked {
		.app--check-trigger {
			background: $primary;
			svg {
				fill: #000;
				opacity: 1;
			}
		}
		.app--check-label {
			color: darken($textWhite, 6);
		}
	}
}

.app--check-trigger {
	width: 22px;
	height: 22px;
	cursor: pointer;
	box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 1), .4);
	background: lighten($primaryBg, 12);
	transition: background-color .2s ease;
	border-radius: 0;
	div {
		font-size: 0;
	}
	svg {
		opacity: 0;
		transition: opacity .2s ease;
		pointer-events: none;
	}
}

.app--check-label {
	margin: 0 0 0 12px;
	color: darken($textWhite, 25);
	font-size: 15px;
	font-weight: 600;
	transition: color .2s ease;
	user-select: none;
}
