.app--input-code {
	&.error {
		.app--input-code-element {
			input {
				border-color: $danger;
			}
		}
	}
	p.error {
		margin: 6px 0 0;
		color: $danger;
		font-size: 14px;
		text-align: right;
		font-weight: 600;
	}
}

.react-code-input {
	input[type='number']::-webkit-outer-spin-button,
	input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

.app--input-code-element {
	display: flex !important;
	flex-direction: row;
	input {
		margin: 0 15px 0 0;
		width: 100%;
		font: 400 14px $font, Sans-serif;
		color: $textWhite;
		border: 1px solid rgba(#fff, .15);
		padding: 10px;
		text-align: center;
		transition: border-color .2s ease;
		&:last-child {
			margin: 0;
		}
	}
}
