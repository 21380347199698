.app-select {
	width: 100%;
	display: flex;
	.app-square-icon {
		margin: 0;
		position: relative;
		min-width: 55px;
		background: $backgroundTertiary;
		box-shadow: none;
		border-radius: 0;
		&:before {
			content: '';
			width: 2px;
			height: 100%;
			position: absolute;
			z-index: 20;
			top: 0;
			right: 0;
			transform: skewX(-6deg);
			background: $backgroundSecondary;
			pointer-events: none;
		}
	}
	&.compact {
		width: auto;
		.app-select--container {
			width: auto;
			svg {
				right: 14px;
			}
		}
		.app-select--element {
			cursor: pointer;
			padding: 10px 14px;
			padding-right: 14px + 10px + 8px;
			background: lighten($backgroundPrimary, 4);
			transition: background-color .2s ease;
			font-weight: 600;
			&:hover {
				background: lighten($backgroundPrimary, 6);
			}
		}
	}
}

.app-select--container {
	width: 100%;
	position: relative;
	svg {
		position: absolute;
		top: calc(50% - 2px);
		right: 20px;
	}
}

.app-select--element {
	width: 100%;
	font: 400 14px $font, Sans-serif;
	color: $textWhite;
	padding: 15px 20px;
	min-height: 50px;
	background: $backgroundTertiary;
	padding-right: 20px + 10px + 8px;
	border-radius: 0;
}

.app-select--message {
	font: 400 14px/20 $font, Sans-serif;
	color: $textGrey;
	margin: 6px 0 0;
}
