.app--bottom-bar {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	.app--title {
		width: auto;
	}
}

.app--bottom-bar-title {
	display: flex;
	justify-content: center;
}

.app--bottom-bar-inner {
	padding: 20px;
	background: $backgroundSecondary;
}
