.app--tabs {
	&.single .app--tabs-tab {
		cursor: default;
	}
}

.app--tabs-head {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 22px;
	padding-bottom: 0;
	// box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 1), .4);
	// border-bottom: 1px solid lighten($backgroundPrimary, 10);
}

.app--tabs-tab {
	min-width: 22%;
	flex-shrink: 0;
	cursor: pointer;
	padding: 10px 12px;
	margin-left: 8px;
	margin-right: 8px;
	text-align: center;
	border-radius: 0;
	background: rgba($backgroundSecondary, .5);
	transition: background-color .2s ease;
	// border-right: 1px solid lighten($backgroundPrimary, 10);
	&.selected {
		background: $backgroundSecondary;
	}
	&:last-child {
		border-right: 0;
	}
	&:not(.selected):hover {
		background: rgba($backgroundSecondary, .8);
	}
	p {
		color: $textGrey;
		font-size: 12.5px;
		transition: color .2s ease;
		font-weight: 700;
		text-transform: uppercase;
	}
	&.selected {
		p {
			color: #fff;
		}
	}
}
