.app--switch {
	padding: 3px;
	cursor: pointer;
	position: relative;
	&:not(.selected):not(:disabled):hover {
		.app--switch-track {
			background: lighten($backgroundPrimary, 16);
		}
		.app--switch-knob {
			background: #fff;
		}
	}
	&.selected {
		.app--switch-track {
			background: $primary;
		}
		.app--switch-knob {
			background: #fff;
			transform: translateX(100%);
		}
	}
}

.app--switch-knob {
	width: 20px;
	height: 20px;
	background: $textGrey;
	position: relative;
	z-index: 20;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	transition: background-color .2s ease, transform .2s ease;
	box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 6), .4);
}

.app--switch-track {
	width: 34px;
	height: 14px;
	position: relative;
	z-index: 10;
	border-radius: 34px;
	background: lighten($backgroundPrimary, 12);
	box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 1), .4);
	transition: background-color .2s ease;
}
