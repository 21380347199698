body {
	margin: 0;
}

input, textarea, button, select {
	border: 0;
	margin: 0;
	padding: 0;
	outline: none;
	background: transparent;
}

textarea {
	resize: vertical;
}

select {
	border-radius: 0;
	-webkit-appearance: none;
}

button:disabled {
	cursor: default;
}

p, h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

pre {
	margin: 0;
	overflow-y: auto;
}
