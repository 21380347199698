.app--button-input {
	position: relative;
	overflow: hidden;
	input {
		position: absolute;
		top: 0;
		left: -50%;
		right: 0;
		width: 150%;
		bottom: 0;
		cursor: pointer;
		opacity: 0;
	}
	&:not(:disabled):hover {
		background: lighten($grey, 8);
	}
	&.app-button--primary {
		background: $primary;
		&:not(:disabled):hover {
			background: lighten($primary, 8);
		}
	}
	&.app-button--secondary {
		background: $secondary;
		&:not(:disabled):hover {
			background: lighten($secondary, 8);
		}
	}
}
