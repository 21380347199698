.app--collapsable {
	padding: 28px;
	&.selected .app--collapsable-body {
		display: block;
	}
	.app--quantity-selector {
		padding: 0;
	}
	.app-legend {
		margin: 28px 0 0;
		h4 {
			background: lighten($backgroundPrimary, 3);
		}
		.app-legend--item {
			padding: 20px;
		}
	}
}

.app--collapsable-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	h6 {
		color: $textGrey;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
	}
}

.app--collapsable-body {
	margin: 28px 0 0;
	display: none;
}
