.app--request-card {
	margin: 0 0 20px;
	cursor: pointer;
	transition: background-color .2s ease, border-color .2s ease;
	background: $backgroundSecondary;
	border-radius: 0;
	&:last-child {
		margin: 0;
	}
	&:hover {
		background-color: lighten($backgroundSecondary, 4);
	}
	&.canceled .request-icon {
		background: $danger;
		svg {
			fill: #fff;
		}
	}
	&.finished .request-icon {
		background: $primary;
		border-radius: 0;
		svg {
			fill: #000;
		}
	}
}

.app--request-card-inner {
	display: flex;
	position: relative;
	align-items: stretch;
	justify-content: space-between;
	.request-content {
		padding: 14px 22px;
		flex-grow: 1;
		flex-shrink: 1;
		h4 {
			padding-right: 20px;
		}
	}
	.request-image {
		width: 94px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 2px solid $backgroundPrimary;
	}
	.request-icon {
		display: flex;
		width: 20px;
		height: 20px;
		background: lighten($backgroundPrimary, 10);
		position: absolute;
		font-size: 0;
		top: 10px;
		right: 10px;
		border-radius: 0;
		svg {
			fill: lighten($backgroundPrimary, 60);
			margin: auto;
		}
	}
	.request-arrow {
		font-size: 0;
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
}
