.app--rating {
	width: 100%;
	display: flex;
	justify-content: space-around;
	.rating--star {
		margin: 0 4px;
		font-size: 0;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
		svg {
			fill: $darkGrey;
			transition: fill .2s ease;
		}
		&.selected svg {
			fill: $primary;
		}
	}
	&.dynamic {
		.rating--star {
			cursor: pointer;
			&:hover svg {
				fill: lighten($darkGrey, 8);
			}
			&.selected:hover svg {
				fill: $primary;
			}
		}
	}
}
