.app--flag {
	cursor: pointer;
	font-family: $font, sans-serif;
	@include backface(124px);
	&:not(.isSelected):hover {
		.app--flag-icon {
			background: lighten($primaryBg, 8);
			border-color: lighten($primaryBg, 16);
		}
		.app--flag-content i {
			p {
				color: #fff;
			}
			svg {
				fill: #fff;
			}
		}
		.app--flag-label {
			color: #fff;
		}
	}
	&.isSelected {
		.app--flag-icon {
			background: lighten($primaryBg, 8);
			border-color: $primary;
		}
		.app--flag-content i {
			p {
				color: $primary;
			}
			svg {
				fill: $primary;
			}
		}
		.app--flag-check {
			opacity: 1;
			transform: translate(0, 0);
		}
		.app--flag-label {
			color: $primary;
		}
	}
	&.text {
		&.isSelected {
			.app--flag-content {
				color: $primary;
			}
		}
		.app--flag-icon {
			padding: 20px;
			display: flex;
		}
		.app--flag-content {
			color: $textGrey;
			margin: auto;
			position: static;
			font-size: 16px;
			font-weight: 700;
			transition: color .2s ease;
		}
	}
	&.is-vertical {
		.app--flag-icon:before {
			display: none;
		}
	}
}

.app--flag-icon {
	overflow: hidden;
	position: relative;
	border: 2px solid lighten($primaryBg, 6);
	background: lighten($primaryBg, 6);
	box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 1), .6);
	border-radius: 0;
	transition: border-color .2s ease, background .2s ease;
	&:before {
		content: '';
		display: block;
		padding: 100% 0 0;
	}
}

.app--flag-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	i {
		margin: auto;
		font-size: 0;
		svg {
			fill: $textGrey;
			transition: fill .2s ease;
		}
		p {
			color: $textGrey;
			font-size: 28px;
			font-weight: 600;
			font-style: normal;
			transition: color .2s ease;
		}
	}
}

.app--flag-check {
	pointer-events: none;
	position: absolute;
	top: 0;
	right: 0;
	width: 20px;
	height: 20px;
	display: flex;
	opacity: 0;
	transform: translate(4px, -4px);
	background: $primary;
	transition: transform .2s ease, opacity .2s ease;
	border-radius: 0;
	transform-origin: top left;
	svg {
		fill: #000;
		margin: auto;
	}
}

.app--flag-label {
	color: $textGrey;
	margin: 10px 0 0;
	font-size: 14px;
	text-align: center;
	transition: color .2s ease;
	font-weight: 600;
}
