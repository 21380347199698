.app--stats-head {
	margin: 0 0 28px;
	text-align: center;
	img {
		width: 64px;
		margin: 0 0 8px;
		display: inline-block;
		border-radius: 0;
	}
	h2 {
		color: darken($textWhite, 6);
		margin: 0 0 6px;
		font-size: 22px;
		font-weight: 600;
	}
	h2 + p {
		color: $textGrey;
		font-size: 15px;
		font-weight: 700;
	}
}
