.app--quantity-selector {
	display: flex;
	user-select: none;
	align-items: center;
	justify-content: center;
	.inner {
		margin: 0 22px;
		@media screen and (max-width: 1060px) {
			margin: 0 12px;
		}
		p.amount {
			font-size: 28px;
			font-weight: 500;
			line-height: .9;
			text-align: center;
		}
		p.label {
			color: $textGrey;
			margin: 4px 0 0;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
	button {
		cursor: pointer;
		svg {
			fill: $primary;
			transition: fill .2s ease;
		}
		&:disabled {
			cursor: not-allowed;
			svg {
				fill: $textGrey;
			}
		}
	}
}
