.page--settings {
	padding: 48px;
	.app-input:not(:last-child),
	.app--input-phone:not(:last-child) {
		margin: 0 0 22px;
	}
	.app--photo-button {
		margin: 0 0 32px;
	}
	.video--legend {
		margin: 40px 0 28px;
	}
	.app-legend:not(:last-child) {
		margin: 0 0 32px;
	}
	.app-button {
		width: 100%;
	}
	.fields {
		margin: 0 0 32px;
	}
	& > .search {
		margin: 0 0 28px;
	}
	.app--grid {
		margin: 0 0 48px;
		&:nth-last-child(2) {
			margin-bottom: 85px;
		}
	}
	h2 {
		color: darken($textWhite, 6);
		margin: 0 auto 28px;
		max-width: 50%;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
		line-height: 1.4;
		&.less-pad {
			margin: 0 auto 10px;
		}
	}
	h2 + p {
		color: $textGrey;
		font-size: 14px;
		text-align: center;
		font-weight: 600;
		margin: 0 0 32px;
	}
	.proceed-btn {
		width: $mainWidth - 2px;
		padding: 18px 22px;
		border-top: 1px solid rgba(lighten($primaryBg, 12), .7);
		background: rgba(lighten($primaryBg, 8), .7);
		backdrop-filter: blur(10px);
		position: fixed;
		bottom: 0;
		right: 0;
		left: calc(50% - ((#{$mainWidth - 2px} - #{$asideWidth}) / 2));
	}
	.app--map-wrap {
		width: $mainWidth - 2px;
		z-index: 90;
		position: fixed;
		left: calc(50% - ((#{$mainWidth - 2px} - #{$asideWidth}) / 2));
		right: 0;
		bottom: 0;
		.map--search {
			top: 56px;
		}
	}
	p.message {
		color: $textGrey;
		margin: 18px 0 0;
		font-size: 15px;
		text-align: center;
		font-weight: 500;
	}
}

@media screen and (max-width: 1060px) {

	.page--settings {
		padding: 22px;
		.proceed-btn,
		.app--map-wrap {
			width: calc(100% - 19px * 2);
			left: 19px;
			right: 19px;
		}
	}

}

@media screen and (max-width: 540px) {

	.page--settings {
		.proceed-btn,
		.app--map-wrap {
			width: 100%;
			left: 0;
			right: 0;
		}
	}

}
