@function in-list($value, $list) {
	@return (false != index($list, $value));
}

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}

@mixin flex(
	$align-items: center,
	$justify-content: null,
	$flex-direction: null
) {
	display: flex;
	align-items: $align-items;
	justify-content: $justify-content;
	flex-direction: $flex-direction;
}

@mixin size($width, $height: null) {
	width: $width;
	height: $height;
	@if $height == null {
		height: $width;
	}
}

@mixin text-truncate {
	min-width: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin overflow($y: null, $x: null) {
	overflow-y: $y;
	overflow-x: $x;
}

@mixin backface($perspective) {
	-webkit-backface-visibility: hidden;
	-webkit-transform: perspective($perspective);
}

@mixin keyframes($name) {
	@-moz-keyframes #{$name} { @content; }
	@-webkit-keyframes #{$name} { @content; }
	@-ms-keyframes #{$name} { @content; }
	@keyframes #{$name} { @content; }
}

@mixin cover(
	$url: null,
	$color: transparent,
	$repeat: no-repeat,
	$position: center center,
	$size: cover
) {
	$content: $color $repeat $position / $size;
	@if $url == null {
		background: $content;
	} @else {
		background: url($url) $content;
	}
}

@mixin font($font-size: null, $font-weight: null, $line-height: null) {
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
}

@mixin radius($value) {
	-webkit-border-radius: $value;
	-moz-border-radius: $value;
	border-radius: $value;
}

@mixin shadow($property...) {
	-webkit-box-shadow: $property;
	-moz-box-shadow: $property;
	box-shadow: $property;
}

@mixin transition($property...) {
	-webkit-transition: $property;
	-moz-transition: $property;
	-ms-transition: $property;
	-o-transition: $property;
	transition: $property;
}

@mixin transform($property...) {
	-webkit-transform: $property;
	-moz-transform: $property;
	-ms-transform: $property;
	-o-transform: $property;
	transform: $property;
}

@mixin select($property) {
	-webkit-user-select: $property;
	-moz-user-select: $property;
	-ms-user-select: $property;
	-o-user-select: $property;
	user-select: $property;
}

@mixin gradient($property...) {
	background-image: -webkit-linear-gradient($property);
	background-image: -moz-linear-gradient($property);
	background-image: -ms-linear-gradient($property);
	background-image: -o-linear-gradient($property);
	background-image: linear-gradient($property);
}

@mixin backdrop-filter($filter) {
	-webkit-backdrop-filter: $filter;
	backdrop-filter: $filter;
}

@mixin position($position, $z-index: null, $top: null, $right: null, $bottom: null, $left: null) {
	position: $position;
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom;
	z-index: $z-index;
}

@mixin absolute($z-index: null, $top: null, $right: null, $bottom: null, $left: null) {
	@include position(
		absolute,
		$top: $top,
		$left: $left,
		$right: $right,
		$bottom: $bottom,
		$z-index: $z-index
	);
}

@mixin fixed($z-index: null, $top: null, $right: null, $bottom: null, $left: null) {
	@include position(
		fixed,
		$top: $top,
		$left: $left,
		$right: $right,
		$bottom: $bottom,
		$z-index: $z-index
	);
}

@mixin absolute-fill(
	$z-index,
	$top: 0,
	$left: 0,
	$right: 0,
	$bottom: 0,
) {
	@include absolute(
		$z-index: $z-index,
		$top: $top,
		$left: $left,
		$right: $right,
		$bottom: $bottom
	);
}

@mixin absolute-center() {
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(
		translate(-50%, -50%)
	);
}

// @mixin ratio($ratio: 0) {
// 	&:before {
// 		content: '';
// 		display: block;
// 		padding: $ratio 0 0;
// 	}
// }

@mixin ratio($width: 0, $height: 0) {
	width: $width;
	min-width: $width;
	&:before {
		content: '';
		display: block;
		padding: percentage($height / $width) 0 0;
	}
}

@mixin placeholder($color, $color-focus: null, $easing: color .2s ease) {
	$selectors: [
		':-moz-placeholder',
		'::-moz-placeholder',
		':-ms-input-placeholder',
		'::-webkit-input-placeholder'
	];
	@each $selector in $selectors {
		&#{$selector} {
			color: $color;
			@include transition($easing);
		}
		@if $color-focus {
			&:focus {
				&#{$selector} { color: $color-focus; }
			}
		}
	}
}

@mixin only-last($number: 1) {
	&:nth-last-child(-n+#{$number}) {
		@content;
	}
}

@mixin except-last($invert: false) {
	$selector: if($invert, ':last-child', ':not(:last-child)');
	&#{$selector} {
		@content;
	}
}

@mixin penultimate($key: 2) {
	&:nth-last-child(#{$key}) {
		@content;
	}
}

@mixin hide-scrollbars {
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin autofill-color($color: white, $size: 30px) {
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		@include shadow(0 0 0 $size $color inset !important);
	}
	&:-webkit-autofill {
		-webkit-text-fill-color: $color !important;
	}
}

@mixin gradient-text($property...) {
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
	@include gradient($property);
}

@mixin text($color: null, $size: null, $weight: null, $height: null) {
	color: $color;
	font-size: $size;
	font-weight: $weight;
	line-height: $height;
}
