.search {
	position: relative;
	.loader, svg {
		position: absolute;
		top: 17px;
		left: 17px;
		@include backface(16px);
	}
	svg {
		opacity: 1;
		pointer-events: none;
		transition: opacity .2s ease;
	}
	input {
		padding-left: 50px + 18px;
	}
	.icon-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 50px;
		height: 50px;
		&:before {
			content: '';
			width: 2px;
			height: 100%;
			position: absolute;
			pointer-events: none;
			top: 0;
			right: -1.5px;
			background-color: $backgroundPrimary;
			transform: skewX(-6deg);
		}
	}
}

.search-loading svg {
	opacity: 0;
}
