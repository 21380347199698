.app--athlete-labels {
	margin: 8px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	div {
		margin: 0 7px;
		font-size: 0;
	}
	svg {
		fill: $primary;
		width: 16px;
		height: auto;
	}
	p {
		color: $primary;
		font-size: 14px;
		font-weight: 700;
	}
}
