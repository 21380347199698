.app--social-link {
	display: flex;
	padding: 14px 18px;
	&:first-child {
		padding-top: 18px;
	}
	&:last-child {
		padding-bottom: 18px;
	}
	align-items: center;
	justify-content: space-between;
	.link-content {
		margin: 0 22px;
		flex-grow: 1;
		flex-shrink: 1;
		overflow: hidden;
	}
	.service {
		color: $textGrey;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
	}
	.link {
		color: $primary;
		margin: 4px 0 0;
		font-size: 12px;
		font-weight: 700;
		text-decoration: underline;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	@media screen and (max-width: 414px) {
		.link-content {
			margin: 0 14px;
		}
		.app-square-icon {
			width: 42px;
			height: 42px;
			min-width: 42px;
			min-height: 42px;
		}
	}
}
