.app-legend {
	&:before {
		content: '';
		display: block;
		height: 10px;
	}
	&.error {
		.app-legend--inner {
			border-color: $danger;
		}
		.app-legend-head h4 {
			color: $danger;
		}
	}
}

.app-legend--inner {
	// border: 1px solid rgba(#fff, .15);
	// background: lighten($backgroundPrimary, 3);
	background: $backgroundSecondary;
	// box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 1), .4);
	transition: border-color .2s ease;
	border-radius: 0;
}

.app-legend-head {
	display: flex;
	justify-content: center;
	// &:before,
	// &:after {
	// 	content: '';
	// 	margin: -1px 0 0;
	// 	height: 1px;
	// 	min-width: 20px;
	// 	flex-shrink: 1;
	// 	border-radius: 0;
	// }
	// &:before {
	// 	margin-right: -1px;
	// 	@include gradient(
	// 		to right,
	// 		transparent,
	// 		lighten($backgroundPrimary, 3)
	// 	);
	// }
	// &:after {
	// 	margin-left: -1px;
	// 	@include gradient(
	// 		to left,
	// 		transparent,
	// 		lighten($backgroundPrimary, 3)
	// 	);
	// }
	h4 {
		color: darken(#fff, 10);
		background: lighten($backgroundSecondary, 4);
		margin: -10px 0 0;
		padding: 0 12px;
		position: relative;
		font-size: 12px;
		transition: color .2s ease;
		text-align: center;
		font-weight: 700;
		line-height: 20px;
		text-transform: uppercase;
		border-radius: 0;
		span {
			position: relative;
			z-index: 11;
		}
		// &:after {
		// 	content: '';
		// 	width: 100%;
		// 	height: 1px;
		// 	background: lighten($backgroundPrimary, 3);
		// 	position: absolute;
		// 	top: 9px;
		// 	left: 0;
		// 	right: 0;
		// 	z-index: 10;
		// }
	}
}

.app-legend-body {
	margin: -10px 0 0;
}

.app-legend--item {
	border-top: 2px solid $backgroundPrimary;
	&:first-child {
		border-top: 0;
	}
}
