.endorsement--athlete {
	padding: 18px;
	display: flex;
	align-items: center;
	img {
		width: 50px;
		height: 50px;
		object-fit: cover;
		border-radius: 0;
	}
	.content {
		margin: 0 16px;
		flex-grow: 1;
		flex-shrink: 1;
		h6 {
			color: darken($textWhite, 6);
			font-size: 15px;
			font-weight: 600;
		}
		h6 + p {
			color: $textGrey;
			margin: 6px 0 0;
			font-size: 14px;
			font-weight: 700;
		}
	}
	.status-icon {
		width: 28px;
		height: 28px;
		margin: 0 0 0 18px;
		display: flex;
		background: lighten($backgroundPrimary, 12);
		border-radius: 0;
		svg {
			fill: $textGrey;
			width: 10px;
			height: auto;
			margin: auto;
		}
		&.status--accepted {
			background: $primary;
			svg {
				fill: #fff;
			}
		}
		&.status--canceled {
			background: $danger;
			svg {
				fill: #fff;
			}
		}
	}
}
